@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400&family=Poppins:wght@300&display=swap');

@font-face {
  font-family: 'RightGroteskWideMedium';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.shopify.com/s/files/1/0446/0462/0962/files/RightGroteskWideMedium.woff2?v=1703127872') format('woff2');
}

@font-face {
  font-family: 'Muller-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.shopify.com/s/files/1/0446/0462/0962/files/Muller-Regular.woff2?v=1703128573') format('woff2');
}

body {
  font-family: 'RightGroteskWideMedium';
  font-weight: 400;
}

.contact-description {
  margin-top: 10px;
}

.tos-container a:not(:last-child) {
  margin-right: 15px;
}


.header-container {
    background-color: #00E0DB;
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

.imgContainer{
    width: 10%;
}

  
.mainContainer {
  width: 30%;
  margin: 0 auto;
  padding: 0px 0px;

}
  
  @media only screen and (max-width: 800px) {
    .mainContainer {
      width: 95%;
      margin: 0 auto;
      padding-bottom: 150px;
    }

    .imgContainer {
      width: 60%;
    }
  }

.header {
  text-align: center;
  text-transform: uppercase;
  margin-top: 70px !important;
  font-weight: 800;
}
  
  .initialService {
    text-align: center;
  }
  
  .paymentTitle {
  	margin-top: 50px;
    font-size: 22px;
    text-align: center;
    position: relative;
  }
  


.paragraph {
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 73px !important;
  font-weight: 800;
}
  
  @media only screen and (max-width: 800px) {
    .header {
      margin-top: 9px !important;
    }
    
    .paragraph {
      font-size: 27px;
      margin-bottom: 37px !important;
    }
  } 

.formEl {
position: relative;
margin: 0 auto;
width: 100%;
}

.inputFields {
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  width: 100%;
  border-radius: 6px;
  border-style: none;
  padding: 16px !important;
  cursor: auto;
 
  transition: all 0.3s cubic-bezier(0.497, 0.51, 0.25, 1) 0s;
}
  
  .inputFields:placeholder {
    color: #c5c5c5
  }
  .inputFields:not(:last-child) {
    margin-bottom: 18px !important;
  }

input.text,input.title,input[type=email],input[type=password],input[type=tel],input[type=text],select,textarea {
    background-color: #fff;
    border: 1px solid #bbb;
    padding: 2px;
    color: #4e4e4e;
}
  
  .Dropdown-root {
    border: 1px solid #bbb;
  }


.half-width-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
}

.half {
  width: 50%;
}




  /*
.name {
  background-image: url(https://denisealley.s3-us-west-2.amazonaws.com/profile-icon-png-910.png);
background-size: 17px 18px;
background-position: 20px 16px;
  background-repeat: no-repeat;
  padding-left: 50px !important;
  margin-bottom: 20px !important;

}
 
  
.address, .zip, .state, .sqft {
  background-image: url(https://denisealley.s3-us-west-2.amazonaws.com/Address.png);
  background-size: 25px 27px;
  background-position: 17px 11px;
  background-repeat: no-repeat;
  padding-left: 50px !important;
  margin-bottom: 20px !important;
}
  */
    .autocomplete-dropdown-container {
    box-shadow: 0 6px 19px 0 rgba(0,0,0,0.23);
  }

  .suggestion-item {
    padding: 20px 10px;
  }

  .suggestion-item--active {
    background-color: lightgrey;
  }
  .suggestion-item--active:hover {
    padding: 20px 10px;
    background: lightgrey;
  }
  /*
  
.phone {
  background-image: url(https://denisealley.s3-us-west-2.amazonaws.com/phone.png);
background-size: 28px 31px;
background-position: 15px 9px;
  background-repeat: no-repeat;
  padding-left: 50px !important;

}
  

.email {
  background: url(https://denisealley.s3-us-west-2.amazonaws.com/kisspng-computer-icons-email-icon-design-equipo-comercial-5b525b3cdb7d21.311695661532123964899.png);
  background-size: 25px 15px;
background-position: 18px 16px;
  background-repeat: no-repeat;
  padding-left: 50px !important;

}
  
  */
.side {
  float: left;
  width: 50%;

}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
	padding-right: 15px;
  }

.next {
  font-family: 'Poppins', sans-serif;
  color: black;
  font-size: 20px;
  font-weight: 700;
  padding-right: 11px;
  text-transform: uppercase;
}

.submitBtn {
  color: white;
  border: black;
  padding: 18px 40px;
  border-radius: 30px;
  justify-self: end;
  background: black url(https://denisealley.s3-us-west-2.amazonaws.com/Digital__Design_99-512.png);
  background-size: 30px 30px;
  background-position: 25px 11px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.submitBtn img {
  height: 40px;
  width: 40px;
}

.serviceTypeContainer {
  display: flex;
  justify-content: center;
}

.serviceType {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  color: black;
  height: 280px;
  width: 350px;
  border-radius: 20px;
  -webkit-box-shadow: 10px 10px 23px -12px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 23px -12px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 23px -12px rgba(0,0,0,0.75);
  cursor: pointer;
  transition: all .2s ease-in;
}

@media only screen and (max-width: 800px) {
  .serviceType {
     height: 180px;
     width: 150px;
  } 
  .btnSelected {
    background-position: 180px 5px;
 } 
 }

 .serviceType:hover {
  background-color: black;
  color: white;
}

.serviceType:not(:last-of-type) {
  margin-right: 20px;
}

.serviceName {
  font-size: 19px;
  font-weight: bold;
}

.textContainer {
  padding-left: 20px;
}

  .ccContainer {
    text-align: center;
  }

.initial {
  font-family: 'MuzzardH';
  text-align: center;
  font-size: 25px;
}

.title {
  font-size: 22px;
  text-align: center;
  margin-top: 40px;

}

.light {
  text-align: center;
  font-size: 15px;
  color: #5c5e62;
 }
 
 .plan {
     padding-left: 20px;
 }
 
 .timeOfDay {
     text-align: center;
     font-size: 22px; 
     margin-top: 40px;
   font-weight: 700;
 
 }
 
 .appointmentContainer {
     text-align: center;
 }
 
.appointmentBtn {
  width: 100%;
  background-color: #00e0db;
  padding: 10px 20px;
  color: #ffffff;
  border-radius: 6px;
  border: 1px solid #00e0db;
  font-size: 20px;
  font-weight: bold;
  transition: all .2s ease-in;
}

.activeAppointmentButton {
  background-color: transparent;
  color: #00e0db;
  border: 2px solid #00e0db;
  /* background-image: url('https://reach-schedule.s3-us-west-1.amazonaws.com/checkmark.png');
  background-repeat: no-repeat;
  background-position: 174px 3px;
  background-size: 30px;  */
}

@media only screen and (min-width: 1200px) {
  .activeAppointmentButton {
    background-position: 355px 3px;
  } 
}


.appointmentBtn:not(:last-of-type) {
  margin-bottom: 10px;
}

.appointmentNotesContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.appointmentTextArea {

  height: 100px;
  padding: 10px 10px;
  border-radius: 12px;
  border: 1px solid #bbb;
  font-size: 16px;
}


.paymentsContainer {
  margin-bottom: 10px;
}

.cc {
  border: 1px solid black;
}




.scheduleContainer {
  display: block;
  position: relative;
  margin-bottom: 5px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
}

.agreementCheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


/* Hide the browser's default checkbox */
.agreementCheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #bbb;
  border-radius: 5px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.agreementCheckbox:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.agreementCheckbox input:checked ~ .checkmark {
  background-color: #00E0DB;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.agreementCheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.agreementCheckbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.completeContainer {
  background-color: #f4f4f4;
  padding: 10px 20px;
  border-radius: 6px;
  font-weight: 600;
  margin-top: 55px;
}

.completeOrder {
    font-family: 'Muller-Regular';
    font-size: 18px;
    background: #00B3FF;
    padding: 15px 35px; 
    border: 1px solid #00B3FF;
    color: white;
    text-transform: uppercase;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    transition: all 2ms ease-in-out;
}

.createCustomer {
  font-family: 'Muller-Regular';
  font-size: 18px;
  background: #000000;
  padding: 15px 35px; 
  border: 1px solid #000000;
  color: white;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: all 2ms ease-in-out;
}


.completeOrder:focus, .completeOrder:hover{
background: #00e0dcc2;
border: 1px solid #00e0dcc2;
}
  
  /* new classes */
.orderSummaryHeader{
width: 100%;
font-weight: bold;
font-size: 28px;
  margin-bottom: 10px;
  color:#333;
}
.orderSummarySubContainer{
width: 100%;

}
.orderSummarySubContainerOne{
width: 100%;
  color: #333;
}
.summaryTotalText{
font-weight: bold;
display: inline-block;
width: 50%;
font-size: 18px;
}
.summaryTotalNumber{
text-align: right;
font-weight: bold;
display: inline-block;
width: 50%;
}
.summaryRecurringText{
font-size: 1rem;
color: #6c757d;
text-align: right;
display: block;
}
.orderSummaryConfirmContainer{
margin-top: 1rem;
width: 100%;
padding-left: 0px;
padding-right: 0px;
margin-bottom: 80px;
}

.align-right {
  text-align: right;
}

.error {
color: red;
font-size: 1.2rem;
font-weight: bold;
text-align: center;
}
  
.Dropdown-control{
    border: none !important;
    color: #757575 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    cursor: pointer;
  }
  
  .Dropdown-option{
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    cursor: pointer;
  }
  
  .Dropdown-option:hover {
    background: #f5f5f5;
  }
  
  .text-danger{
    color: red;
    margin-left: 1rem;
    font-weight: 600;
  }

  .d-inline-block{
    display: inline-block;
    margin-left: .25rem;
    font-size: 18px;
  }
  
  
  

.booked{
text-align: center;
}
  
  .loadingContainer{
  display: block;
  position: fixed;
  z-index: 100;
  background: rgb(102, 102, 102, .6);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.loader{
  position: absolute;
  top:35%;
  left: 50%;
}

#ccnumber input, #ccexp input, #cvv input {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  border: 1px solid red !important;
}


  .small-text {
    font-size: 16px;
    font-weight: 600;
  }

  .invisible {
    visibility: hidden;
  }

  .tpc-flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .original-price {
    text-decoration: line-through;
    padding-left: 10px
  }
/*
.header{
    font-family: 'MuzzardH';
    font-size: 85px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 70px!important;
}

.paragraph{
    font-family: 'MuzzardH-ExtraLight';
    font-size: 35px;
    letter-spacing: 5px;
    text-align: center;
    margin-bottom: 73px!important;
}

.inputFields{
    color: #4e4e4e;
    box-sizing: border-box;
    font-size: 14px;
    text-align: left;
    width: 100%;
    border: 1px solid #bbb;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 18px;
    transition: all .3s cubic-bezier(.497,.51,.25,1) 0s;
}
*/

.inputFields_Label {
  display: block;
  text-align: left;
  margin-left: 5px;
  margin-bottom: 2px;
}

.createContainer {
  padding: 0px 0px;
}

.service-selection {
  font-family: 'RightGroteskWideMedium';
  width: 100%;
  /* border: 3px solid #3e6ae1; */
  border-radius: 6px;
  /* margin-top: 16px;
  margin-bottom: 16px; */
  padding: 0px 0px;
}

.service-image-container {
  position: relative;
}

.service-image {
  width: 100%;
}

/* .service-image-container  {
  width: 100%;
  height: 115px;
  background-color: orange;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('https://wordpress-953428-3897840.cloudwaysapps.com/wp-content/uploads/2023/12/cleaning.png');
} */

.service-information {
 padding: 10px 16px;
}

.service-rating {
  width: 75px;
}

.service-information p {
  padding: 0px 0px;
  margin: 0px 0px;
}

.serviceTitle {
  font-size: 16px;
}

.service-description {
  font-size: 14px;
  color: #5c5e62;
}

.first-clean-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.first-clean-container p {
  font-size: 18px;
}

.breakdown-container {
  text-align: right;
}

.breakdown-container p {
  margin-bottom: 0px;
}

.breakdown-container .service-description {
  font-size: 14px;
}

.appointment-loading {
  background-color: #f4f4f4;
  padding: 80px 20px;
  border-radius: 6px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.payment-methods {
  text-align: center;
}